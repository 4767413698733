.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  width: 100%;
  height: calc(100% - 4.8rem);
  background-color: #f4f4f4;
  overflow: hidden;
}
