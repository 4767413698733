.container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  z-index: 20;
  /* background-color: rgba(0, 0, 0, 0.05); */

  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_container {
  background-color: transparent;
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem;

  /* box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%); */

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
}

.text {
  color: #fff;
  font-size: 1.3rem;
}
