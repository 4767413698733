@import "~antd/dist/antd.css";
@import "react-perfect-scrollbar/dist/css/styles.css";

.ant-modal-body {
  padding: 0;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 16px;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 16px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-header {
  padding: 1.6rem !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 1.2rem 0 1.2rem 1.6rem;
}

/* Divider */
.ant-divider-horizontal {
  margin: 1rem 0;
}

/* Tab */
.ant-tabs-nav {
  padding: 0 1.6rem;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}

/* Date picker */
.ant-picker-input > input {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.65);
}

/*tab*/
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}
