.container {
  width: 100%;
  height: 4.8rem;
  background-color: #373737;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
}

.logo {
  width: 2.5rem;
}

.menu_container {
  height: 100%;
  width: calc(100% - 18rem);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menu_item_container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.text_menu {
  /* color: #f5f5f7c9; */
  color: #fff;
  font-size: 1.2rem;
}

.text_menu:hover {
  cursor: pointer;
  color: #f5f5f7;
}
